@font-face {
	font-family: Lato-Regular;
	src: url('../../../Fonts/Lato/Lato-Regular.ttf') format('truetype');
}

@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap');

.logo {
    height: 50vh;
}
.bannerText1 {
    height: 40vh;
    color: white;
    font-family: Lato-Regular;
    font-weight: normal;
    font-size: 2.5vh;
    overflow: hidden;
}
.logoBackground {
    /*background: url(../../Content/Images/Headers/header.png) no-repeat center;
    background-size: 100%;
    height: 50vh;
    width: 100%;
    position: absolute;
    top: 75px;
    left: 0;*/
    background: url(../../Content/Images/Headers/header.png) no-repeat center;
    width: 100vw;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: block;
    margin-top: 0 !important;
    margin: 0 !Important;
    padding: 0 !Important;
    border: none !Important;
    position: relative;
    overflow: hidden;
    left: auto;
    top: -20px;
    height: 60vh;
}
#bannerHr1 {
    color: white;
    width: 100%;
    border-color: white;
    border-width: 1.5px;
    margin-left: 0;
    margin-top: 0;
    overflow: hidden;
}
#bannerHeader11 {
    font-size: 4.5vh;
    margin: 0;
    font-weight: normal;
    overflow: hidden;
}
h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
    margin-top: 0;
    margin-bottom: 0;
    overflow: hidden;
}

.mobileGallery{
    height: 250vw;

}

.mobileGallerySemi1 {
    height: 120vw;
}
.mobileGallerySemi2 {
    height: 150vh;
}

.portfolioH2Title {
    color: black;
    font-family: Lato-Regular;
    font-size: 4vh;
    text-align: justify;
    line-break: strict;
    margin-bottom: 1vh;
    font-weight: normal;
    margin-left: 3vh;
    
}

#portfolioH2TitleHr {
    color: black;
    width: 50%;
    border-color: black;
    border-width: 2px;
    margin-left: 0;
    margin-top: 0;
    overflow: hidden;
    margin-bottom: 5vh;
}
.galleryFormatMiddle{
    height:20vh;
}
.galleryFormatBottom {
    height: 2vh;
}
.elementFormat{
    margin-left:50vw;
}