@font-face {
	font-family: Lato-Regular;
	src: url('../../../Fonts/Lato/Lato-Regular.ttf') format('truetype');
}

.slideshowContainer{
    margin:auto;
    height: 100vh;
}
.backgroundForHomeOffer {
    background: url(../../Content/Images/HomeImages/OfferBackground.jpg) no-repeat center;
    width: 100vw;
    height: 67vh;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: block;
    margin-top: 0 !important;
    margin: 0 !Important;
    padding: 0 !Important;
    border: none !Important;
    position: relative;
    overflow: hidden;
}
.backgroundForHomeOfferMobile {
    background: url(../../Content/Images/HomeImages/OfferBackground.jpg) no-repeat center;
    width: 100vw;
    height: 185vh;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: block;
    margin-top: 0 !important;
    margin: 0 !Important;
    padding: 0 !Important;
    border: none !Important;
    position: relative;
    overflow: hidden;
}
.backgroundForHomeOfferMobileElement {
    width: 100vw;
    height: 45vh;
}
.backgroundForHomeArguments {
    background-color: #f2fcff;
    width: 100vw;
    height: 37vh;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: block;
    margin-top: 0 !important;
    margin: 0 !Important;
    padding: 0 !Important;
    border: none !Important;
    position: relative;
    overflow: hidden;
    top: -20px;
}
.backgroundForHomeArgumentsMobile {
    background-color: #f2fcff;
    width: 100vw;
    height: 45vh;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: block;
    margin-top: 0 !important;
    margin: 0 !Important;
    padding: 0 !Important;
    border: none !Important;
    position: relative;
    overflow: hidden;
    top: -20px;
}

.offerHomeText {
    color: white;
    font-family: Lato-Regular;
    font-size: 1.8vh;
    text-align: justify;
    line-break: strict;
}
.offerHomeH2 {
    color: white;
    font-family: Lato-Regular;
    font-size: 3vh;
    text-align: justify;
    line-break: strict;
    margin-bottom: 1vh;
    font-weight: bold;
}
.offerHomeH2Title {
    color: white;
    font-family: Lato-Regular;
    font-size: 4vh;
    text-align: justify;
    line-break: strict;
    margin-bottom: 1vh;
    font-weight: bold;
    margin-left:3vh;
}
.offerHomeH2TitleHr {
    color: white;
    width: 100%;
    border-color: white;
    border-width: 1.5px;
    margin-left: 0;
    margin-top: 0;
    overflow: hidden;
}
.homeOfferIcon {
    height: 20vh;
}
#homeHr {
    color: white;
    width: 50%;
    border-color: white;
    border-width: 1.5px;
    margin-left: 20%;
    margin-top: 4vh;
    overflow: hidden;
}
.homeArgumentsIcon {
    height:15vh;
}
.homeArgumentsIconSemi {
    height: 20vh;
    display: block;
    margin-left: auto;
    margin-right: auto;
}
.offerHomeTextBlack {
    color: #1a3b49;
    font-family: Lato-Regular;
    font-size: 1.8vh;
    text-align:justify;
    line-break:strict;
}
.offerHomeH2Black {
    color: #1a3b49;
    font-family: Lato-Regular;
    font-size: 3vh;
    text-align: justify;
    line-break: strict;
    margin-bottom:1vh;
    font-weight:bold;
}
#homeHrBlack {
    color: #1a3b49;
    width: 30%;
    border-color: #1a3b49;
    border-width: 1.5px;
    margin-left: 0;
    margin-top: 0.5vh;
    overflow: hidden;
}
.pipeline {
    border-left: 1px solid #1a3b49;
    height: 20vh;
}
.mobileSlideShow {
    height:100vh;
}

.carousel-control-next-icon,
.carousel-control-prev-icon{
    filter: invert(1);
}
.carousel-indicators li {
    background-color: darkgray;
}