@font-face {
	font-family: Lato-Light;
	src: url('../Fonts/Lato/Lato-Light.ttf') format('truetype');
}

@font-face {
	font-family: Lato-Regular;
	src: url('../Fonts/Lato/Lato-Regular.ttf') format('truetype');
}

@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap');

a.navbar-brand {
    white-space: normal;
    text-align: center;
    word-break: break-all;
}
#navbarBrandAdditionalEdit {
    background-color: #1a3b49;
    color: white;
    margin-bottom: 0;
    max-width:100vw;

}

#navbarUlAdditionalEdit:hover {
    transition: .2s ease;
    box-shadow: inset 0 0 20px #235d70;
}
#navTextEdit{
    font-family:Lato-Light;
    font-size: 24px;
}

#navbarLi {
    font-weight: lighter;
}
    #navbarLi:hover {
        transition: .2s ease;
        box-shadow: inset 0 0 20px #235d70;
    }
html {
    font-size: 14px;
}
@media (max-width: 100vw) {
    html {
        font-size: 16px;
        font-family: Lato-Regular;
    }
}

.box-shadow {
  box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .05);
}

#noMargin{
    margin-bottom:0;
}

.headerStyle{
    max-width: 100vw;
    overflow:hidden;
}
.custom-toggler .navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255,255,255, 2)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
}
.custom-toggler.navbar-toggler {
    border-color: white;
}
.custom-container{
    width: 10vw;
}
