@import url(https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap);
@font-face {
	font-family: Lato-Light;
	src: url(/static/media/Lato-Light.2fe27d9d.ttf) format('truetype');
}

@font-face {
	font-family: Lato-Regular;
	src: url(/static/media/Lato-Regular.2d36b1a9.ttf) format('truetype');
}

a.navbar-brand {
    white-space: normal;
    text-align: center;
    word-break: break-all;
}
#navbarBrandAdditionalEdit {
    background-color: #1a3b49;
    color: white;
    margin-bottom: 0;
    max-width:100vw;

}

#navbarUlAdditionalEdit:hover {
    transition: .2s ease;
    box-shadow: inset 0 0 20px #235d70;
}
#navTextEdit{
    font-family:Lato-Light;
    font-size: 24px;
}

#navbarLi {
    font-weight: lighter;
}
    #navbarLi:hover {
        transition: .2s ease;
        box-shadow: inset 0 0 20px #235d70;
    }
html {
    font-size: 14px;
}
@media (max-width: 100vw) {
    html {
        font-size: 16px;
        font-family: Lato-Regular;
    }
}

.box-shadow {
  box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .05);
}

#noMargin{
    margin-bottom:0;
}

.headerStyle{
    max-width: 100vw;
    overflow:hidden;
}
.custom-toggler .navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255,255,255, 2)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
}
.custom-toggler.navbar-toggler {
    border-color: white;
}
.custom-container{
    width: 10vw;
}

.formFormat {
    width: 30vw;
    background-color: #262626;
    color: white;
    font-family: Lato-Regular;
    font-weight: normal;
    position: relative;
    font-size: 2vh;
}
.formFormatMobile {
    width: 80vw;
    background-color: #262626;
    color: white;
    font-family: Lato-Regular;
    font-weight: normal;
    position: relative;
    left: 5vw;
    font-size: 2vh;
}

.footerLayout {
    background-color: #262626;
    width: 100vw;
    color: white;
}
.footerUpBelt {
    background-color: #262626;
    /*background-color: #243949;*/
    width: 100vw;
    height: 3vh;
}
.footerUpBeltMobile {
    background-color: #262626;
    width: 100vw;
    height: 3vh;
    position: relative;
    top: 2vh;
}
.footerDownBelt {
    background-color: #262626;
    width: 100vw;
    height: 2vh;
}
.footerDownBeltMobile {
    background-color: #262626;
    width: 100vw;
    height: 2vh;
    position:relative;
    top: 0;
}

.littleIcon {
    height: 4vh;
}

.littleIconText {
    font-family: Lato-Regular;
    font-size: 2vh;
    position:relative;
    left:0;
}
.littleIconTextMobile {
    font-family: Lato-Regular;
    font-size: 2vh;
    position: relative;
    left: 5vw;
}

#phone {
    left: -18vw;
}

#mail {
    left: -13vw;
}

#loc {
    left: -15vw;
}

.littleIconContainer {
    width: 30vw;
    height: 6vh;
}


.footerTablePosition {
    float: right;
    width: 50vh;
    position: relative;
    background-color: #262626;
    left: -25vw;
    top: 10vh;
    font-size: 2vh;
    font-family: Lato-Regular;
    color: white;
}
.footerContainer {
    background-color: #262626;
}
.SecretsFooterMain {
    background-color: #262626;
    width: 100vw;
    overflow:hidden;
    height:70vh;
}

@font-face {
	font-family: Lato-Regular;
	src: url(/static/media/Lato-Regular.2d36b1a9.ttf) format('truetype');
}

.logo {
    height: 50vh;
}
.logoMobile {
    height: 30vh;
}

.logoSemiMobile {
    height: 30vh;
    display: block;
    margin: auto;
}

.bannerText {
    height: 40vh;
    color: white;
    font-family: Lato-Regular;
    font-weight: normal;
    font-size: 2.2vh;
    overflow: hidden;
}

#bannerHr {
    color: white;
    width: 100%;
    border-color: white;
    border-width: 1.5px;
    margin-left: 0;
    margin-top: 0;
    overflow: hidden;
}

#bannerHeader1 {
    font-size: 3.5vh;
    margin: 0;
    font-weight: normal;
    overflow: hidden;
}

.logoBackground {
    /*background: url(../../Content/Images/Headers/header.png) no-repeat center;
    background-size: 100%;
    height: 50vh;
    width: 100%;
    position: absolute;
    top: 75px;
    left: 0;*/
    background: url(/static/media/header.790349cb.png) no-repeat center;
    width: 100vw;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: block;
    margin-top: 0 !important;
    margin: 0 !Important;
    padding: 0 !Important;
    border: none !Important;
    position: relative;
    overflow: hidden;
    left: auto;
    top: -20px;
    height: 60vh;
}

.logoBackgroundMobile {
    /*background: url(../../Content/Images/Headers/header.png) no-repeat center;
    background-size: 100%;
    height: 50vh;
    width: 100%;
    position: absolute;
    top: 75px;
    left: 0;*/
    background: url(/static/media/header.790349cb.png) no-repeat center;
    width: 100vw;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: block;
    margin-top: 0 !important;
    margin: 0 !Important;
    padding: 0 !Important;
    border: none !Important;
    position: relative;
    overflow: hidden;
    left: auto;
    top: -20px;
    height: 40vh;
}



h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
    margin-top: 0;
    margin-bottom: 0;
    overflow: hidden;
}

.layoutContainerFormat {
    margin-top: 5vh;
    margin-bottom: 10vh;
}
.appLayout{
    max-width: 100vw;
    overflow:hidden;
}
/* Provide sufficient contrast against white background */
a {
  color: #0366d6;
}

code {
  color: #E01A76;
}

.btn-primary {
    color: #fff;
    background-color: #262626;
    border-color: white;
}

@font-face {
	font-family: Lato-Regular;
	src: url(/static/media/Lato-Regular.2d36b1a9.ttf) format('truetype');
}

.slideshowContainer{
    margin:auto;
    height: 100vh;
}
.backgroundForHomeOffer {
    background: url(/static/media/OfferBackground.647108b0.jpg) no-repeat center;
    width: 100vw;
    height: 67vh;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: block;
    margin-top: 0 !important;
    margin: 0 !Important;
    padding: 0 !Important;
    border: none !Important;
    position: relative;
    overflow: hidden;
}
.backgroundForHomeOfferMobile {
    background: url(/static/media/OfferBackground.647108b0.jpg) no-repeat center;
    width: 100vw;
    height: 185vh;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: block;
    margin-top: 0 !important;
    margin: 0 !Important;
    padding: 0 !Important;
    border: none !Important;
    position: relative;
    overflow: hidden;
}
.backgroundForHomeOfferMobileElement {
    width: 100vw;
    height: 45vh;
}
.backgroundForHomeArguments {
    background-color: #f2fcff;
    width: 100vw;
    height: 37vh;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: block;
    margin-top: 0 !important;
    margin: 0 !Important;
    padding: 0 !Important;
    border: none !Important;
    position: relative;
    overflow: hidden;
    top: -20px;
}
.backgroundForHomeArgumentsMobile {
    background-color: #f2fcff;
    width: 100vw;
    height: 45vh;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: block;
    margin-top: 0 !important;
    margin: 0 !Important;
    padding: 0 !Important;
    border: none !Important;
    position: relative;
    overflow: hidden;
    top: -20px;
}

.offerHomeText {
    color: white;
    font-family: Lato-Regular;
    font-size: 1.8vh;
    text-align: justify;
    line-break: strict;
}
.offerHomeH2 {
    color: white;
    font-family: Lato-Regular;
    font-size: 3vh;
    text-align: justify;
    line-break: strict;
    margin-bottom: 1vh;
    font-weight: bold;
}
.offerHomeH2Title {
    color: white;
    font-family: Lato-Regular;
    font-size: 4vh;
    text-align: justify;
    line-break: strict;
    margin-bottom: 1vh;
    font-weight: bold;
    margin-left:3vh;
}
.offerHomeH2TitleHr {
    color: white;
    width: 100%;
    border-color: white;
    border-width: 1.5px;
    margin-left: 0;
    margin-top: 0;
    overflow: hidden;
}
.homeOfferIcon {
    height: 20vh;
}
#homeHr {
    color: white;
    width: 50%;
    border-color: white;
    border-width: 1.5px;
    margin-left: 20%;
    margin-top: 4vh;
    overflow: hidden;
}
.homeArgumentsIcon {
    height:15vh;
}
.homeArgumentsIconSemi {
    height: 20vh;
    display: block;
    margin-left: auto;
    margin-right: auto;
}
.offerHomeTextBlack {
    color: #1a3b49;
    font-family: Lato-Regular;
    font-size: 1.8vh;
    text-align:justify;
    line-break:strict;
}
.offerHomeH2Black {
    color: #1a3b49;
    font-family: Lato-Regular;
    font-size: 3vh;
    text-align: justify;
    line-break: strict;
    margin-bottom:1vh;
    font-weight:bold;
}
#homeHrBlack {
    color: #1a3b49;
    width: 30%;
    border-color: #1a3b49;
    border-width: 1.5px;
    margin-left: 0;
    margin-top: 0.5vh;
    overflow: hidden;
}
.pipeline {
    border-left: 1px solid #1a3b49;
    height: 20vh;
}
.mobileSlideShow {
    height:100vh;
}

.carousel-control-next-icon,
.carousel-control-prev-icon{
    -webkit-filter: invert(1);
            filter: invert(1);
}
.carousel-indicators li {
    background-color: darkgray;
}
@font-face {
	font-family: Lato-Regular;
	src: url(/static/media/Lato-Regular.2d36b1a9.ttf) format('truetype');
}

.logo {
    height: 50vh;
}
.bannerText1 {
    height: 40vh;
    color: white;
    font-family: Lato-Regular;
    font-weight: normal;
    font-size: 2.5vh;
    overflow: hidden;
}
.logoBackground {
    /*background: url(../../Content/Images/Headers/header.png) no-repeat center;
    background-size: 100%;
    height: 50vh;
    width: 100%;
    position: absolute;
    top: 75px;
    left: 0;*/
    background: url(/static/media/header.790349cb.png) no-repeat center;
    width: 100vw;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: block;
    margin-top: 0 !important;
    margin: 0 !Important;
    padding: 0 !Important;
    border: none !Important;
    position: relative;
    overflow: hidden;
    left: auto;
    top: -20px;
    height: 60vh;
}
#bannerHr1 {
    color: white;
    width: 100%;
    border-color: white;
    border-width: 1.5px;
    margin-left: 0;
    margin-top: 0;
    overflow: hidden;
}
#bannerHeader11 {
    font-size: 4.5vh;
    margin: 0;
    font-weight: normal;
    overflow: hidden;
}
h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
    margin-top: 0;
    margin-bottom: 0;
    overflow: hidden;
}

.mobileGallery{
    height: 250vw;

}

.mobileGallerySemi1 {
    height: 120vw;
}
.mobileGallerySemi2 {
    height: 150vh;
}

.portfolioH2Title {
    color: black;
    font-family: Lato-Regular;
    font-size: 4vh;
    text-align: justify;
    line-break: strict;
    margin-bottom: 1vh;
    font-weight: normal;
    margin-left: 3vh;
    
}

#portfolioH2TitleHr {
    color: black;
    width: 50%;
    border-color: black;
    border-width: 2px;
    margin-left: 0;
    margin-top: 0;
    overflow: hidden;
    margin-bottom: 5vh;
}
.galleryFormatMiddle{
    height:20vh;
}
.galleryFormatBottom {
    height: 2vh;
}
.elementFormat{
    margin-left:50vw;
}
.layoutContainerFormat {
    margin-top: 5vh;
    margin-bottom: 10vh;
}

@font-face {
	font-family: Lato-Regular;
	src: url(/static/media/Lato-Regular.2d36b1a9.ttf) format('truetype');
}

.offerTextBlack {
    color: black;
    font-family: Lato-Regular;
    font-size: 1.8vh;
    text-align: justify;
    line-break: strict;
}

#offerHrBlack {
    color: black;
    width: 70%;
    border-color: black;
    border-width: 2px;
    margin-left: 0;
    margin-top: 0.5vh;
    overflow: hidden;
}

.offerOfferIcon {
    height: 40vh;
}
.offerOfferIconSemi {
    height: 35vw;
    display: block;
    margin-left: auto;
    margin-right: auto;
}
.offerDiv {
    height: 60vh;
}
.offerH2Title {
    color: black;
    font-family: Lato-Regular;
    font-size: 4vh;
    text-align: justify;
    line-break: strict;
    margin-bottom: 1vh;
    font-weight: normal;
}
.offerPresentationSpace{
    height: 340vh;
}

.offerIcon {
    height: 30vh;
}
.backgroundForOfferMobileElement {
    width: 100vw;
    height: 90vh;
}
.backgroundForOfferMobileElementSmall {
    width: 100vw;
    height: 75vh;
}
