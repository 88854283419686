@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap');


.formFormat {
    width: 30vw;
    background-color: #262626;
    color: white;
    font-family: Lato-Regular;
    font-weight: normal;
    position: relative;
    font-size: 2vh;
}
.formFormatMobile {
    width: 80vw;
    background-color: #262626;
    color: white;
    font-family: Lato-Regular;
    font-weight: normal;
    position: relative;
    left: 5vw;
    font-size: 2vh;
}

.footerLayout {
    background-color: #262626;
    width: 100vw;
    color: white;
}
.footerUpBelt {
    background-color: #262626;
    /*background-color: #243949;*/
    width: 100vw;
    height: 3vh;
}
.footerUpBeltMobile {
    background-color: #262626;
    width: 100vw;
    height: 3vh;
    position: relative;
    top: 2vh;
}
.footerDownBelt {
    background-color: #262626;
    width: 100vw;
    height: 2vh;
}
.footerDownBeltMobile {
    background-color: #262626;
    width: 100vw;
    height: 2vh;
    position:relative;
    top: 0;
}

.littleIcon {
    height: 4vh;
}

.littleIconText {
    font-family: Lato-Regular;
    font-size: 2vh;
    position:relative;
    left:0;
}
.littleIconTextMobile {
    font-family: Lato-Regular;
    font-size: 2vh;
    position: relative;
    left: 5vw;
}

#phone {
    left: -18vw;
}

#mail {
    left: -13vw;
}

#loc {
    left: -15vw;
}

.littleIconContainer {
    width: 30vw;
    height: 6vh;
}


.footerTablePosition {
    float: right;
    width: 50vh;
    position: relative;
    background-color: #262626;
    left: -25vw;
    top: 10vh;
    font-size: 2vh;
    font-family: Lato-Regular;
    color: white;
}
.footerContainer {
    background-color: #262626;
}
.SecretsFooterMain {
    background-color: #262626;
    width: 100vw;
    overflow:hidden;
    height:70vh;
}
