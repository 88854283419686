@font-face {
	font-family: Lato-Regular;
	src: url('../../../Fonts/Lato/Lato-Regular.ttf') format('truetype');
}

.offerTextBlack {
    color: black;
    font-family: Lato-Regular;
    font-size: 1.8vh;
    text-align: justify;
    line-break: strict;
}

#offerHrBlack {
    color: black;
    width: 70%;
    border-color: black;
    border-width: 2px;
    margin-left: 0;
    margin-top: 0.5vh;
    overflow: hidden;
}

.offerOfferIcon {
    height: 40vh;
}
.offerOfferIconSemi {
    height: 35vw;
    display: block;
    margin-left: auto;
    margin-right: auto;
}
.offerDiv {
    height: 60vh;
}
.offerH2Title {
    color: black;
    font-family: Lato-Regular;
    font-size: 4vh;
    text-align: justify;
    line-break: strict;
    margin-bottom: 1vh;
    font-weight: normal;
}
.offerPresentationSpace{
    height: 340vh;
}

.offerIcon {
    height: 30vh;
}
.backgroundForOfferMobileElement {
    width: 100vw;
    height: 90vh;
}
.backgroundForOfferMobileElementSmall {
    width: 100vw;
    height: 75vh;
}